window.apiClient = axios.create({
    baseURL: '',
    withCredentials: true,
    crossDomain: true,
    headers: {
        "content-type": "application/json"
    }
});

window.globalCopy = function(id) {
  var copyText = document.querySelector(id);
  copyText.select();
  document.execCommand("copy");
}

window.loadGlobal = function(status) {
  if(status){
    $('body').append('<div class="load-bg"></div>');
    if($(window).width()<=768){
      document.body.style.overflow = 'hidden';
    }
  }else{
    $('.load-bg').remove();
    document.body.style.overflow = 'auto';
  }
}

window.HandlingError = function(error, validator){
    if (error.response) {
        if(error.response.status==422){
          console.log(error.response.data.errors);
          validator.resetForm();
          try {
            validator.showErrors(error.response.data.errors);
          } catch (error) {
            console.log(error);
          }

          
        }else{
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
        
        
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log('Error', error.message);
      }
      //console.log(error.config);
};


window.toFixed = function(num, fixed) {
  var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
  return num.toString().match(re)[0];
}


window.percentMask = function(el){
  IMask(el, {
      mask: [
          {
              mask: '00.0'
          }
      ],
  });
}

window.currencyMask = function(el){
  IMask(el, {
      mask: [
          { mask: '' },
          {
              mask: 'num',
              lazy: false,
              blocks: {
                  num: {
                      mask: Number,
                      scale: 2,
                      thousandsSeparator: '.',
                      padFractionalZeros: true,
                      radix: ',',
                      mapToRadix: ['.'],
                  }
              }
          }
      ],
  });
}



