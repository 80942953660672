
import moment from 'moment';

var maskInputs = function () {
    
    var phone = function() {
        let phones = document.querySelectorAll(".phone");
        for (var i = 0; i < phones.length; i++) {
            IMask(phones[i], {
                mask: [
                    {
                    mask:'(00) 0000-0000',
                    },
                    {
                    mask:'(00) 00000-0000',
                    },
                ],
            });
        }
    }


    var birthday = function() {
        let birthdays = document.querySelectorAll(".birthday");
        for (var i = 0; i < birthdays.length; i++) {
            IMask(birthdays[i], {
                mask: [
                    {
                    mask:'00/00/0000',
                    },
                ],
            });
        }
    }

    var birthdayEvent = function() {
        let momentFormat = 'DD/MM/YYYY';
        let birthdays = document.querySelectorAll(".birthdayEvent");
        for (var i = 0; i < birthdays.length; i++) {
            IMask(birthdays[i], {
                mask: Date,
                pattern: momentFormat,
                el: birthdays[i],
                blocks: {
                    YYYY: {
                        mask: IMask.MaskedRange,
                        from: 1930,
                        to: 2030
                    },
                    MM: {
                        mask: IMask.MaskedRange,
                        from: 1,
                        to: 12
                    },
                    DD: {
                        mask: IMask.MaskedRange,
                        from: 1,
                        to: 31
                    }
                },
                format: function (date) {
                  
                    
                    if(moment().diff(date, 'years',false)<18){
                        if(this.el.parentElement.parentElement.querySelector('input[type="checkbox"]')){
                            this.el.parentElement.parentElement.querySelector('input[type="checkbox"]').disabled=false;
                        }
                    }else{
                        if(this.el.parentElement.parentElement.querySelector('input[type="checkbox"]')){
                            this.el.parentElement.parentElement.querySelector('input[type="checkbox"]').disabled=true;
                        }
                    }
                    return moment(date).format(momentFormat);
                },
                parse: function (str) {
                    return moment(str, momentFormat);
                },
                min: new Date(1900, 0, 1),  
                max: new Date()
            }).on("complete",function(e){
            });
        }
    }
   

    var cpf = function() {
        let cpfs = document.querySelectorAll(".cpf");
        for (var i = 0; i < cpfs.length; i++) {
            IMask(cpfs[i], {
                mask: [
                    {
                    mask:'000.000.000-00',
                    },
                ],
            });
        }
    }

    var cep = function() {
        let ceps = document.querySelectorAll(".cep");
        for (var i = 0; i < ceps.length; i++) {
            IMask(ceps[i], {
                mask: [
                    {
                    mask:'00000-000',
                    },
                ],
            });
        }
    }

    //^(?!0\.00)[1-9]\d{0,2}(,\d{3})*(\.\d\d)?$
    var currencyMask = function(){

        let prices = document.querySelectorAll(".price");
        for (var i = 0; i < prices.length; i++) {
            window.currencyMask(prices[i]);
            
        }

    }

    var percentMask = function(){

        let percents = document.querySelectorAll(".percent");
        for (var i = 0; i < percents.length; i++) {
            window.percentMask(percents[i]);
        }

    }
    return {
        init: function() {
            phone();
            currencyMask();
            percentMask();
            birthday();
            birthdayEvent();
            cpf();
            cep();
        }
    };
}();
    
$(function () {
    maskInputs.init();
});
